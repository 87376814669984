<template>
    <a v-if="$helper.userCapability('view',$route)" :href="path" target="_blank" class="custom-btn">
        <BootstrapIcon icon="eye" size="1x"/>
    </a>
</template>

<script>
export default {
    name: "ViewBtn",
    props: {
        path: {type: String, default: ""},
    },
};
</script>
